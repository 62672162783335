import React, { useEffect } from 'react';
import { useAdminReportUrlContext } from './AdminReportUrlContextProvider';
import { ApolloError } from '@apollo/client';
import useAdminReport, { AdminReportData } from '../hooks/useAdminReport';
import { useAuthContext } from './AuthContextProvider';
import useSettings from '../hooks/useSettings';
import { MigrationStatus } from '../constants/migrationStatus';

const QuickSightUrlManager: React.FC<{
	children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
	const { getUrl, handleAdminReportData } = useAdminReportUrlContext();
	const { authState } = useAuthContext();
	const { settings } = useSettings();

	const callSupergraph =
		authState.migrationStatus === MigrationStatus.MIGRATED;

	const {
		data,
		loading,
		error,
	}: { data: AdminReportData; loading: boolean; error?: ApolloError } =
		useAdminReport(callSupergraph, authState.isAuthenticated);

	useEffect(() => {
		switch (authState.migrationStatus) {
			case MigrationStatus.MIGRATED:
				handleAdminReportData(data, loading, error);
				break;
			case MigrationStatus.IN_PROGRESS:
				window.location.href =
					settings?.offlinePage.url ||
					'https://skillbuilder.aws/offline';
				break;
			case MigrationStatus.NOT_MIGRATED:
			default:
				getUrl();
		}
	}, [
		getUrl,
		loading,
		data,
		error,
		handleAdminReportData,
		authState.migrationStatus,
		settings,
	]);

	return <>{children}</>;
};

export default QuickSightUrlManager;
