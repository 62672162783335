import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';

import { GandalfUser } from '../interfaces/gandalfUser';
import useSettings from '../hooks/useSettings';
import { getCurrentUser, handleSignIn } from '../utils/auth';
import { getLogger } from '../utils/logger';
import { ERROR_STRING } from '../constants/common';
import { DimensionValue } from '../interfaces/logger';
import { MigrationStatus } from '../constants/migrationStatus';

export interface AuthState {
	isAuthenticated: boolean;
	authUser: GandalfUser | null;
	authError: Error | null;
	migrationStatus: MigrationStatus;
}

const defaultState: AuthState = {
	isAuthenticated: false,
	authUser: null,
	authError: null,
	migrationStatus: MigrationStatus.NOT_MIGRATED,
};

export interface IAuthContext {
	authState: AuthState;
	setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const useAuthContext = (): IAuthContext => {
	const context = useContext(AuthContext);
	if (context === null) {
		throw new Error(
			'useAuthContext must be used within a AuthContextProvider tag',
		);
	}
	return context;
};

const AuthContextProvider: React.FC<{
	initialState?: AuthState;
	children: React.ReactNode;
}> = ({
	initialState,
	children,
}: {
	initialState?: AuthState;
	children: ReactNode;
}) => {
	const { settings } = useSettings();
	const [authState, setAuthState] = useState<AuthState>(
		initialState || defaultState,
	);
	const logger = getLogger();

	useEffect(() => {
		if (!authState.authUser && !!settings) {
			logger.context = {
				sessionStart: Date.now(),
			};
			logger.info('Pre-Authentication');
			getCurrentUser()
				.then((authUser) => {
					logger.context = {
						vibeId: authUser.vibeId,
						hatId: authUser.hatId,
						sessionStart: `${authUser.vibeId}-${Date.now()}`,
					};
					logger.info('Logged in.');
					return setAuthState({
						isAuthenticated: true,
						authUser,
						authError: null,
						migrationStatus: authUser.migrationStatus,
					});
				})
				.catch((error) => {
					if (error === ERROR_STRING.NOT_AUTHENTICATED) {
						handleSignIn();
					} else {
						logger.error(
							'Error captured in auth context provider',
							error,
						);
						logger.counterMetric({
							metricName: 'AuthError',
							dimensionValue: DimensionValue.Error,
						});
						setAuthState({
							isAuthenticated: false,
							authUser: null,
							authError: error,
							migrationStatus: MigrationStatus.NOT_MIGRATED,
						});
					}
				});
		}
	}, [authState.migrationStatus, authState.authUser, settings, logger]);

	return (
		<AuthContext.Provider value={{ authState, setAuthState }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;
