import React, { useCallback, useState } from 'react';

import quicksightUrlApi from '../api/quicksightUrlApi';
import { FetchStatus } from '../constants/fetchStatus';
import { useAuthContext } from './AuthContextProvider';
import { getLogger } from '../utils/logger';
import { DimensionValue } from '../interfaces/logger';
import UnauthorizedSBARAdminAccessError from '../errors/UnauthorizedSBARAdminAccessError';
import { AdminReportData } from '../hooks/useAdminReport';
import { ApolloError } from '@apollo/client';
import useSettings from '../hooks/useSettings';
import UnauthorizedAdminSBAIRAccessError from '../errors/UnauthorizedAdminSBAIRAccessError';
import { UnregisteredUserAccessError } from '../errors';

interface AdminReportUrlState {
	url?: string;
	status: FetchStatus;
	error?: Error;
	piiAccess?: boolean;
}

export interface AdminReportUrlContextProps extends AdminReportUrlState {
	handleAdminReportData: (
		data: AdminReportData | undefined,
		loading: boolean,
		error?: ApolloError,
	) => void;
	getUrl: () => void;
	resetStates: () => void;
	adminUrl: string;
	setAdminUrl: (value: string) => void;
}

const DEFAULT_ADMIN_REPORT_URL_STATE: AdminReportUrlState = {
	status: FetchStatus.Unspecified,
	piiAccess: false,
};

export const AdminReportUrlContext =
	React.createContext<AdminReportUrlContextProps | null>(null);

export const useAdminReportUrlContext = (): AdminReportUrlContextProps => {
	const contextState = React.useContext(AdminReportUrlContext);
	if (contextState === null) {
		throw new Error(
			'useAdminReportUrlContext must be used within a AdminReportUrlProvider tag',
		);
	}
	return contextState;
};

const isExpected = (error: Error): boolean => {
	return [UnauthorizedSBARAdminAccessError].some((e) => error instanceof e);
};

export const AdminReportUrlContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
	const logger = getLogger();
	const { authState } = useAuthContext();
	const [adminReportUrlState, setAdminReportUrlState] =
		useState<AdminReportUrlState>(DEFAULT_ADMIN_REPORT_URL_STATE);
	const { settings } = useSettings();
	const [adminUrl, setAdminUrl] = useState<string>(
		settings?.skillbuilderReports?.doceboAdministratorDashboard || '',
	);

	const handleSBAIRAdminReportError = useCallback(
		(error: ApolloError) => {
			const errorMessage = error.message;
			// Error message when user is not authorized to invoke the resolver: https://tiny.amazon.com/ejf4h06/codeamazpackSBAIblobc592src
			const unauthErrorMessagePattern = /NOT_AUTHORIZED/;
			const unauthMatch = errorMessage.match(unauthErrorMessagePattern);

			// Error message when user is not registered in Quicksight: https://tiny.amazon.com/18g4f106t/codeamazpackSBAIblobc592src
			const unregisteredUserMessagePattern = /RESOURCE_NOT_FOUND/;
			const unregisteredMatch = errorMessage.match(
				unregisteredUserMessagePattern,
			);

			if (unauthMatch) {
				logger.counterMetric({
					metricName: 'UnauthorizedError',
					dimensionValue: DimensionValue.Error,
				});
				setAdminUrl(settings?.skillbuilder?.hcLearnerPage || '');
				setAdminReportUrlState({
					status: FetchStatus.ExpectedError,
					error: new UnauthorizedAdminSBAIRAccessError(errorMessage),
				});
			} else if (unregisteredMatch) {
				logger.counterMetric({
					metricName: 'UnregisteredError',
					dimensionValue: DimensionValue.Error,
				});
				setAdminUrl(settings?.skillbuilder?.hcLearnerPage || '');
				setAdminReportUrlState({
					status: FetchStatus.ExpectedError,
					error: new UnregisteredUserAccessError(errorMessage),
				});
			} else {
				logger.error(
					'Unexpected error captured in SBAIR AdminReportUrlContextProvider',
					error,
				);
				logger.counterMetric({
					metricName: 'UnexpectedError',
					dimensionValue: DimensionValue.Error,
				});
				setAdminReportUrlState({
					status: FetchStatus.UnexpectedError,
					error: error,
				});
			}
		},
		[logger, settings?.skillbuilder?.hcLearnerPage],
	);

	const resetStates = useCallback(() => {
		setAdminReportUrlState(DEFAULT_ADMIN_REPORT_URL_STATE);
	}, [setAdminReportUrlState]);

	const handleAdminReportData = useCallback(
		(data: AdminReportData | undefined, loading: boolean, error?) => {
			resetStates();
			setAdminUrl(
				settings?.skillbuilderReports?.skillbuilderHcAdminDashboard ||
					'',
			);
			if (loading) {
				setAdminReportUrlState({ status: FetchStatus.Loading });
			} else if (error) {
				handleSBAIRAdminReportError(error);
			} else if (data) {
				const isPIIEnabled =
					data.adminReport.accessList.includes('PIIEnabled');
				setAdminReportUrlState({
					status: FetchStatus.Loaded,
					url: data.adminReport.reportEmbeddingUrl,
					piiAccess: isPIIEnabled ? true : false,
				});
			}
		},
		[
			resetStates,
			handleSBAIRAdminReportError,
			setAdminUrl,
			settings?.skillbuilderReports?.skillbuilderHcAdminDashboard,
		],
	);

	const getUrl = useCallback(async () => {
		if (authState.isAuthenticated && logger) {
			resetStates();
			setAdminUrl(
				settings?.skillbuilderReports?.doceboAdministratorDashboard ||
					'',
			);

			setAdminReportUrlState({ status: FetchStatus.Loading });
			quicksightUrlApi
				.getUrl()
				.then(({ result }) => {
					setAdminReportUrlState({
						status: FetchStatus.Loaded,
						url: result.EmbedUrl,
					});
				})
				.catch((error) => {
					if (isExpected(error)) {
						setAdminUrl(
							settings?.skillbuilder?.doceboHomeUrl || '',
						);
						setAdminReportUrlState({
							status: FetchStatus.ExpectedError,
							error: error,
						});
					} else {
						setAdminUrl(
							settings?.skillbuilder?.doceboHomeUrl || '',
						);
						logger.error(
							'Unexpected error captured in AdminReportUrlContextProvider',
							error,
						);
						logger.counterMetric({
							metricName: 'AdminReportUrlContextError',
							dimensionValue: DimensionValue.Error,
						});
						setAdminReportUrlState({
							status: FetchStatus.UnexpectedError,
							error: error,
						});
					}
				});
		}
	}, [
		resetStates,
		authState.isAuthenticated,
		logger,
		setAdminUrl,
		settings?.skillbuilderReports?.doceboAdministratorDashboard,
		settings?.skillbuilder?.doceboHomeUrl,
	]);

	return (
		<AdminReportUrlContext.Provider
			value={{
				...adminReportUrlState,
				handleAdminReportData: handleAdminReportData,
				getUrl: getUrl,
				resetStates,
				adminUrl,
				setAdminUrl: setAdminUrl,
			}}
		>
			{children}
		</AdminReportUrlContext.Provider>
	);
};

export default AdminReportUrlContextProvider;
